import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/auth/login/login.component';
import { AuthGuard } from './guard/auth.guard';
import { ForgotComponent } from './pages/auth/forgot/forgot.component';
import { ResetComponent } from './pages/auth/reset/reset.component';
import { CollectionManagementModule } from './pages/collection-management/collection-management.module';
import { ArmManagementModule } from './pages/arm-management/arm-management.module';
import { TaxShippingManagementModule } from './pages/tax-shipping-management/tax-shipping-management.module';
import { DiamondManagementModule } from './pages/diamond-management/diamond-management.module';

const routes: Routes = [
  { path: 'user', loadChildren: () => import('./pages/user-management/user-management.module').then(m => m.UserManagementModule), canActivate: [AuthGuard] },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotComponent },
  { path: 'reset-password', component: ResetComponent },

  { path: 'dashboard', loadChildren: () => import('./pages/dashboard-management/dashboard-management.module').then(m => m.DashboardManagementModule), canActivate: [AuthGuard] },
  { path: 'product', loadChildren: () => import('./pages/product-management/product-management.module').then(m => m.ProductManagementModule), canActivate: [AuthGuard] },
  { path: 'custom-product', loadChildren: () => import('./pages/custom-product-management/custom-product-management.module').then(m => m.CustomProductManagementModule), canActivate: [AuthGuard] },
  { path: 'category', loadChildren: () => import('./pages/category-management/category-management.module').then(m => m.CategoryManagementModule), canActivate: [AuthGuard] },
  { path: 'sub-category', loadChildren: () => import('./pages/subcategory-management/subcategory-management.module').then(m => m.SubcategoryManagementModule), canActivate: [AuthGuard] },
  { path: 'swatches', loadChildren: () => import('./pages/swatches-management/swatches-management.module').then(m => m.SwatchesManagementModule), canActivate: [AuthGuard] },
  { path: 'embellishment', loadChildren: () => import('./pages/embellishment-management/embellishment-management.module').then(m => m.EmbellishmentManagementModule), canActivate: [AuthGuard] },
  { path: 'coupon', loadChildren: () => import('./pages/coupon-management/coupon-management.module').then(m => m.CouponManagementModule), canActivate: [AuthGuard] },
  { path: 'appointment', loadChildren: () => import('./pages/appointment-management/appointment-management.module').then(m => m.AppointmentManagementModule), canActivate: [AuthGuard] },
  { path: 'order', loadChildren: () => import('./pages/order-management/order-management.module').then(m => m.OrderManagementModule), canActivate: [AuthGuard] },
  { path: 'color', loadChildren: () => import('./pages/color-management/color-management.module').then(m => m.ColorManagementModule), canActivate: [AuthGuard] },
  { path: 'visitor', loadChildren: () => import('./pages/visitor-management/visitor-management.module').then(m => m.VisitorManagementModule), canActivate: [AuthGuard] },
  { path: 'static-content-management', loadChildren: () => import('./pages/static-content-management/static-content-management.module').then(m => m.StaticContentManagementModule), canActivate: [AuthGuard] },
  { path: 'transaction-management', loadChildren: () => import('./pages/transaction-management/transaction-management.module').then(m => m.TransactionManagementModule), canActivate: [AuthGuard] },
  { path: 'setting', loadChildren: () => import('./pages/setting/setting.module').then(m => m.SettingModule), canActivate: [AuthGuard] },
  { path: 'faq', loadChildren: () => import('./pages/static-content-management/faq-management/faq-management.module').then(m => m.FaqManagementModule), canActivate: [AuthGuard] },
  { path: 'sub-admin', loadChildren: () => import('./pages/sub-admin-management/sub-admin-management.module').then(m => m.SubAdminManagementModule), canActivate: [AuthGuard] },
  { path: 'silhouette', loadChildren: () => import('./pages/silhouette-management/silhouette-management.module').then(m => m.SilhouetteManagementModule), canActivate: [AuthGuard] },
  { path: 'neckline', loadChildren: () => import('./pages/neckline-management/neckline-management.module').then(m => m.NecklineManagementModule), canActivate: [AuthGuard] },
  { path: 'price', loadChildren: () => import('./pages/price-management/price-management.module').then(m => m.PriceManagementModule), canActivate: [AuthGuard] },
  { path: 'trade', loadChildren: () => import('./pages/trade-management/trade-management.module').then(m => m.TradeManagementModule), canActivate: [AuthGuard] },
  { path: 'trunk', loadChildren: () => import('./pages/trunk-management/trunk-management.module').then(m => m.TrunkManagementModule), canActivate: [AuthGuard] },
  { path: 'blog', loadChildren: () => import('./pages/static-content-management/blog-management/blog-management.module').then(m => m.BlogManagementModule), canActivate: [AuthGuard] },
  { path: 'press', loadChildren: () => import('./pages/static-content-management/press-management/press-management.module').then(m => m.PressManagementModule), canActivate: [AuthGuard] },
  { path: 'real-brides', loadChildren: () => import('./pages/static-content-management/real-brides-management/real-brides-management.module').then(m => (m.RealBridesManagementModule)) },
  { path: 'homepage', loadChildren: () => import('./pages/static-content-management/homepage-management/homepage-management.module').then(m => (m.HomepageManagementModule)) },
  { path: 'body-management', loadChildren: () => import('./pages/body-management/body-management.module').then(m => m.BodyManagementModule), canActivate: [AuthGuard] },
  { path: 'skirt-management', loadChildren: () => import('./pages/skirt-management/skirt-management.module').then(m => m.SkirtManagementModule) },
  { path: 'mask-management', loadChildren: () => import('./pages/mask-management/mask-management.module').then(m => m.MaskManagementModule) },
  { path: 'product-category', loadChildren: () => import('./pages/product-category-management/product-category-management.module').then(m => m.ProductCategoryManagementModule) },
  { path: 'collection-management', loadChildren: () => import('./pages/collection-management/collection-management.module').then(m => CollectionManagementModule) },
  { path: 'arm-management', loadChildren: () => import('./pages/arm-management/arm-management.module').then(m => ArmManagementModule) },
  { path: 'tax-shipping-management', loadChildren: () => import('./pages/tax-shipping-management/tax-shipping-management.module').then(m => TaxShippingManagementModule) },
  { path: 'diamond-management', loadChildren: () => import('./pages/diamond-management/diamond-management.module').then(m => DiamondManagementModule) },
  { path: 'premium-color', loadChildren: () => import('./pages/premium-color-management/premium-color-management.module').then(m => m.PremiumColorManagementModule), canActivate: [AuthGuard] },
  { path: 'ready-product-management', loadChildren: () => import('./pages/ready-product-management/ready-product-management.module').then(m => m.ReadyProductManagementModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
